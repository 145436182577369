<template>
  <div class="table-view-content">
    <table>
      <!-- HEAD -->
      <thead class="table-view-content__head">
        <tr>
          <th
            v-for="({ label, sort }, i) in tableHeader"
            :key="i"
            @click="handleSorting(sort)"
            :data-testid="sort ? `headerTable-${sort}` : ''"
          >
            <div class="flex align-center">
              <bg-text size="title-5" class="header-label">{{ label }}</bg-text>
              <template v-if="sort">
                <bg-icon
                  v-if="isActiveSort(sort, 'asc')"
                  name="up"
                  size="sm"
                  class="ml-16"
                />
                <bg-icon
                  v-else
                  name="down"
                  size="sm"
                  :class="[
                    'ml-16',
                    {
                      'table-view-content__sort--no-active':
                        !isActiveSort(sort, 'desc') && sortingBy,
                    },
                  ]"
                />
              </template>
            </div>
          </th>
        </tr>
      </thead>

      <!-- BODY -->
      <tbody>
        <template v-if="loadingTable">
          <tr v-for="i in 5" :key="i">
            <td v-for="j in 10" :key="j">
              <bg-skeleton :width="'100%'" :height="24" />
            </td>
          </tr>
        </template>

        <template v-else>
          <tr v-for="(room, index) in roomList" :key="`room-list-${index}`">
            <td>
              <bg-text size="body-1">
                {{ room.type }}
              </bg-text>
            </td>
            <td>
              <bg-text size="body-1">
                {{ room.number }}
              </bg-text>
            </td>
            <td>
              <bg-label-rainbow :color="getRoomStatusColor(room.roomStatus)">
                <span class="table-view-content__room-status">
                  {{ getRoomStatusText(room.roomStatus) }}
                </span>
              </bg-label-rainbow>
            </td>
            <template v-if="isOccupiedRoom(room.roomStatus)">
              <td role="cell" @click="goToContractDetail(room.contractId)">
                <bg-text
                  class="table-view-content__contract-id"
                  size="label-2"
                  underline
                >
                  {{ room.contractId }}
                </bg-text>
              </td>
              <td>
                <bg-text size="label-1" class="mb-4">{{ room.tenant }}</bg-text>
                <bg-text size="label-2">{{ room.phoneNumber }}</bg-text>
              </td>
              <td>
                <bg-label-rainbow
                  :color="getBookingStatus(room.status.label).color"
                >
                  {{ getBookingStatus(room.status.label).text }}
                </bg-label-rainbow>
              </td>
              <td>
                <bg-text size="body-2">{{ room.checkin }}</bg-text>
              </td>
              <td>
                <bg-text size="body-2">{{ room.checkout }}</bg-text>
              </td>
              <td>
                <bg-text size="body-2">{{ room.dueDate }}</bg-text>
              </td>
              <td>
                <bg-text size="body-2">
                  {{
                    getRentDuration(room.rentPrice.period, room.rentDuration)
                  }}
                </bg-text>
              </td>
            </template>
            <template v-else>
              <td v-for="i in 7" :key="i">-</td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="no-data" v-if="!roomList.length && !loadingTable">
      <bg-text size="title-2">Data Tidak Ditemukan</bg-text>
      <bg-text size="body-2">
        Data tidak ditemukan di filter atau kata kunci yang Anda gunakan.
      </bg-text>
    </div>

    <note-modal :is-open.sync="isModalNoteOpen" />
  </div>
</template>

<script>
import { getBookingStatus } from 'Utils/bookingStatus';

import { BgText, BgLabelRainbow, BgIcon, BgSkeleton } from 'bangul-vue';

import NoteModal from '@admin_molecules/NoteModal';

import { RENT_TYPE_DATA } from './constants/rentPeriod';
import { SORTING_ORDER } from './constants/sortingOrder';

export default {
  name: 'TableViewContent',

  components: {
    BgText,
    BgLabelRainbow,
    BgIcon,
    BgSkeleton,
    NoteModal,
  },

  props: {
    loadingTable: {
      type: Boolean,
      default: true,
    },
    roomList: {
      type: Array,
      default: () => [],
      /* Is a list of objects:
				bookingcode
				penyewa
				status.label
				status.date
				kamar.number
				kamar.type
				checkin
				checkout
				biayaSewa.biaya
				biayaSewa.periode
				jatuhTempo
				statusInvoice.label
				statusInvoice.date
				invoiceId
				contractId
			*/
    },
  },

  data() {
    return {
      isModalNoteOpen: false,
      tableHeader: [
        {
          label: 'Tipe Kamar',
          sort: '',
        },
        {
          label: 'Nomor Kamar',
          sort: '',
        },
        {
          label: 'Room Status',
          sort: '',
        },
        {
          label: 'Contract ID',
          sort: '',
        },
        {
          label: 'Penyewa',
          sort: '',
        },
        {
          label: 'Status Tenant',
          sort: '',
        },
        {
          label: 'Check-in Date',
          sort: 'check_in_date',
        },
        {
          label: 'Check-out Date',
          sort: 'check_out_date',
        },
        {
          label: 'Jatuh Tempo',
          sort: 'due_date',
        },
        {
          label: 'Durasi Sewa',
          sort: '',
        },
      ],
      sortingBy: '',
      sortingOrder: '',
    };
  },

  mounted() {
    window.addEventListener('click', this.blurListener);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.blurListener);
  },

  methods: {
    getBookingStatus,

    getRentDuration(period, rentDuration) {
      const { text, count } = RENT_TYPE_DATA.find(
        rentType => rentType.value === period
      );

      const totalRentDuration = count * rentDuration;

      return `${totalRentDuration} ${text}`;
    },

    isOccupiedRoom(roomStatus) {
      return roomStatus === 'occupied';
    },

    getRoomStatusColor(roomStatus) {
      return this.isOccupiedRoom(roomStatus) ? 'green' : 'white';
    },

    getRoomStatusText(roomStatus) {
      if (roomStatus === 'out_of_order') return 'Out Of Order';

      return roomStatus;
    },

    isActiveSort(sort, order) {
      return this.sortingBy === sort && this.sortingOrder === order;
    },

    async handleSorting(sortingBy) {
      if (!sortingBy || this.loadingTable) return;

      if (this.sortingBy === sortingBy) {
        this.sortingOrder =
          this.sortingOrder === SORTING_ORDER.ascending
            ? SORTING_ORDER.descending
            : SORTING_ORDER.ascending;
      } else {
        this.sortingBy = sortingBy;
        this.sortingOrder = SORTING_ORDER.ascending;
      }

      const sortingData = {
        sort_by: this.sortingBy,
        sort_order: this.sortingOrder,
      };

      this.$emit('handle-sorting', sortingData);
    },

    goToContractDetail(contractId) {
      this.$router.push(`/room-allotment/contract-detail/${contractId}`);
    },

    blurListener(e) {
      const isThisComponent =
        e.target.parentNode?._prevClass === 'option-detail' ||
        e.target._prevClass === 'option-detail';

      if (!isThisComponent) {
        this.roomList.forEach(item => {
          item.showOptions = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./TableViewContent.scss" scoped></style>
