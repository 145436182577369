export const RENT_TYPE_DATA = [
  {
    value: 'day',
    text: 'Hari',
    count: 1,
  },
  {
    value: 'week',
    text: 'Minggu',
    count: 1,
  },
  {
    value: 'month',
    text: 'Bulan',
    count: 1,
  },
  {
    value: 'year',
    text: 'Tahun',
    count: 1,
  },
  {
    value: '3_month',
    text: 'Bulan',
    count: 3,
  },
  {
    value: '6_month',
    text: 'Bulan',
    count: 6,
  },
];
