<template>
  <div class="organism-header-table-view">
    <div class="organism-header-table-view__row">
      <div class="organism-header-table-view__col">
        <div class="organism-header-table-view__property-select">
          <bg-skeleton v-if="!propertyListValue" width="100%" />
          <bg-select
            v-else
            data-testid="headerTableView-SearchProperty"
            size="md"
            :value="propertyListValue"
            :options="propertyListSelect"
            searchable
            @input="handleSelectProperty"
          />
        </div>
      </div>

      <div class="organism-header-table-view__col">
        <bg-search-bar
          v-model="searchValue"
          type="text"
          size="md"
          class="organism-header-table-view__search"
          with-select-input
          :select-options="searchOptions"
          :select-value="searchBy"
          :placeholder="placeholderSearchBy"
          :show-clear-text-icon="false"
          @select-change="handleChangeSearchBy"
          @keyup.enter="getSearchValue"
        />

        <bg-button-icon
          class="bg-u-ml-xs"
          icon="filter"
          @click="$emit('filter-clicked')"
        />
      </div>
    </div>

    <div class="organism-header-table-view__row">
      <div class="organism-header-table-view__col">
        <div class="organism-header-table-view__wrap-today">
          <bg-datepicker
            size="md"
            minimum-view="month"
            v-model="selectedMonthYear"
            format="MMMM yyyy"
            data-testid="headerTableView-datePicker"
            :disabled-dates="disabledMonths"
            @selected="getMonthYear"
          />
        </div>
      </div>
      <div class="organism-header-table-view__col">
        <div class="flex align-center justify-end">
          <bg-button-icon icon="activity" @click="goToChangeLog">
            Riwayat perubahan data kamar
          </bg-button-icon>

          <bg-button-icon
            class="bg-u-ml-sm"
            icon="tenant-bill"
            @click="handleCalendarViewButtonClicked"
          >
            Tampilan Calendar
          </bg-button-icon>

          <add-tenant-menu-button
            class="bg-u-ml-sm"
            :property-id="xSelectedPropertyId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { dateFormatterToSend } from 'Utils/formatter';

import {
  BgSelect,
  BgButtonIcon,
  BgSkeleton,
  BgDatepicker,
  BgSearchBar,
} from 'bangul-vue';

import AddTenantMenuButton from '../RoomAllotment/AddTenantMenuButton';

dayjs.extend(isToday);

export default {
  name: 'HeaderTableView',

  components: {
    BgSelect,
    BgButtonIcon,
    BgSkeleton,
    BgDatepicker,
    BgSearchBar,
    AddTenantMenuButton,
  },

  props: {
    // For property select dropdown on top left
    propertyListSelect: {
      type: Array,
      default: /* istanbul ignore next */ () => [],
    },
    activeFilters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedMonthYear: dayjs().toDate(),
      queryParams: {},
      searchValue: '',
      searchBy: 'booking_code',
      searchOptions: [
        {
          val: 'booking_code',
          label: 'Booking Code',
        },
        {
          val: 'tenant_name',
          label: 'Nama',
        },
        {
          val: 'phone',
          label: 'Nomor Handphone',
        },
        {
          val: 'room_number',
          label: 'Nomor Kamar',
        },
        {
          val: 'unit_type',
          label: 'Tipe Kamar',
        },
      ],
    };
  },

  watch: {
    activeFilters: {
      deep: true,
      handler(value) {
        const { start_date: startDate, end_date: endDate, ...filter } = value;

        if (startDate && endDate) {
          this.selectedMonthYear = dayjs(startDate).toDate();
        } else {
          this.selectedMonthYear = dayjs().toDate();
        }

        this.queryParams.filter = filter;
        this.queryParams.start_date = startDate;
        this.queryParams.end_date = endDate;
      },
    },
  },

  computed: {
    xSelectedPropertyId() {
      return this.$store.getters.xSelectedPropertyId || 0;
    },

    placeholderSearchBy() {
      const { label } = this.searchOptions.find(
        ({ val }) => val === this.searchBy
      );

      return `Masukkan ${label}`;
    },

    propertyListValue: {
      get() {
        return this.$store.state.roomAllotment.xSelectedProperty.val;
      },
      set(value) {
        const found = this.propertyListSelect.find(({ val }) => val === value);
        this.$store.commit('xSetSelectedProperty', found);
      },
    },

    disabledMonths() {
      return {
        to: dayjs(new Date()).subtract(3, 'year').toDate(),
      };
    },
  },

  methods: {
    handleCalendarViewButtonClicked() {
      this.$emit('redirect-to-calendar-view', true);
    },

    handleChangeSearchBy(val) {
      this.searchBy = val;
      this.searchValue = '';
    },

    addQueryParams(payload) {
      const { input, value } = payload;

      switch (input) {
        case 'monthYear':
          this.queryParams.start_date = value.start_date;
          this.queryParams.end_date = value.end_date;
          this.$emit('month-changed');
          break;
        case 'search':
          this.queryParams.search_value = value.search_value;
          this.queryParams.search_by = value.search_by;
          break;
        default:
          return;
      }

      this.$emit('emit-query-params', this.queryParams);
    },

    getMonthYear(date) {
      const firstDayOfMonth = dateFormatterToSend(dayjs(date).startOf('month'));
      const endDayOfMonth = dateFormatterToSend(dayjs(date).endOf('month'));

      this.addQueryParams({
        input: 'monthYear',
        value: {
          start_date: firstDayOfMonth,
          end_date: endDayOfMonth,
        },
      });
    },

    handleSelectProperty(value) {
      this.propertyListValue = value;
      this.$emit('emit-update-query-string', true);
    },

    getSearchValue(input) {
      const { value } = input.target;

      this.addQueryParams({
        input: 'search',
        value: {
          search_by: value ? this.searchBy : '',
          search_value: value,
        },
      });
    },

    goToChangeLog() {
      this.$router.push({
        name: `room-allotment-change-log`,
        params: {
          propertyId: this.xSelectedPropertyId,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./HeaderTableView.scss" scoped></style>
