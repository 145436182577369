<template>
  <div class="page-room-allotment-table-view">
    <div class="skeleton" v-if="loadingAll">
      <bg-skeleton :width="'50%'" :height="24" style="margin-bottom: 130px" />
      <div>
        <bg-skeleton :width="'100%'" :height="24" style="margin-bottom: 48px" />
        <bg-skeleton :width="'100%'" :height="24" style="margin-bottom: 48px" />
        <bg-skeleton :width="'100%'" :height="24" style="margin-bottom: 40px" />
      </div>
    </div>

    <template v-else>
      <header-table-view
        class="page-room-allotment-table-view__header"
        :propertyListSelect="propertyListSelect"
        :active-filters="activeFilters"
        @filter-clicked="isFilterModalOpen = true"
        @redirect-to-calendar-view="redirectToCalendarView"
        @month-changed="handleMonthChanged"
        @emit-query-params="newQueryParams"
        @emit-update-query-string="updateQueryString"
      />

      <table-view-content
        :loading-table="loadingTable"
        :room-list="propertyListTable"
        @handle-sorting="handleSorting"
      />

      <room-allotment-filter-modal
        data-testid="headerTableView-Filter"
        v-model="isFilterModalOpen"
        ref="filterModal"
        :active-filters="activeFilters"
        @on-reset-filter="resetFilter"
        @on-apply-filter="handleApplyFilter"
      />

      <bg-pagination
        v-if="totalPagination > 1"
        :value="activePagination"
        @click="onPageChanged"
        :pageTotal="totalPagination"
      />
    </template>
  </div>
</template>

<script>
import { BgSkeleton, BgPagination } from 'bangul-vue';

import { mapMutations, mapGetters } from 'vuex';

import { dateFormatterToDisplay } from 'Utils/formatter';

import HeaderTableView from '@admin_organisms/HeaderTableView';
import TableViewContent from '@admin_organisms/TableViewContent';
import roomAllotmentApi from '@admin/api/endpoints/room-allotment';

export default {
  name: 'RoomAllotmentTableView',
  components: {
    BgSkeleton,
    BgPagination,
    HeaderTableView,
    TableViewContent,
    RoomAllotmentFilterModal: () =>
      import('@admin_molecules/RoomAllotmentFilterModal'),
  },

  data() {
    return {
      propertyListSelect: [],
      propertyListTable: [],
      propertyId: '',
      propertyName: '',
      loadingAll: false,
      loadingTable: false,
      offset: 0,
      pagination: {},
      sorting: {},
      newParams: {},
      isFilterModalOpen: false,
      activeFilters: {},
    };
  },

  computed: {
    ...mapGetters([
      'xSelectedPropertyIsEmpty',
      'xSelectedPropertyId',
      'xSelectedPropertyName',
      'xPropertyListIsEmpty',
    ]),

    totalPagination() {
      return Math.ceil(this.pagination.total_items / this.pagination.limit);
    },
    activePagination() {
      return this.pagination?.current_page;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(value) {
        /*
        There are 3 scenarios how to access this table view page
        1. /room-allotment/table-view directly
        2. /room-allotment/table-view?property_id=xxx directly
        3. or /room-allotment/table-view?property_id=xxx after one of the 2 scenario above
        */

        if (value.query.property_id) {
          const queryPropertyId = value.query.property_id;

          if (this.xPropertyListIsEmpty) {
            // Scenario 2
            // If state property list is empty, get the list from the apis
            // And get the property object that equal to current property_id query string value
            (async () => {
              await this.processingPropertyListItems();
              const found = this.propertyListSelect.find(
                ({ val }) => String(val) === queryPropertyId
              );
              this.xSetSelectedProperty(
                found || this.propertyListSelect?.[0] || {}
              );
              this.$route.meta.breadcrumbs[1].name = this.xSelectedPropertyName;
              this.xSetPropertyList(this.propertyListSelect);
              this.newQueryParams();
            })();
          } else {
            // Scenario 3
            // If state property list is already have the all property
            // Get the selected / query string property_id data
            this.propertyListSelect = this.$store.state.roomAllotment.xPropertyList;
            this.$route.meta.breadcrumbs[1].name = this.xSelectedPropertyName;
            this.newQueryParams();
          }
        } else {
          // Scenario 1
          // If query not exist property_id is not exist,
          // Get the property list and select the first property on it
          (async () => {
            await this.processingPropertyListItems();
            this.xSetSelectedProperty(this.propertyListSelect[0]);
            this.$route.meta.breadcrumbs[1].name = this.xSelectedPropertyName;
            this.xSetPropertyList(this.propertyListSelect);
            this.updateQueryString();
          })();
        }
      },
    },
  },

  methods: {
    ...mapMutations(['xSetSelectedProperty', 'xSetPropertyList']),

    handleMonthChanged() {
      delete this.activeFilters.start_date;
      delete this.activeFilters.end_date;
    },

    resetFilter() {
      const ignoredKeys = ['search_by', 'search_value'];

      Object.keys(this.newParams).forEach(key => {
        if (!ignoredKeys.includes(key)) {
          delete this.newParams[key];
        }
      });

      this.offset = 0;
      this.pagination = {};
      this.activeFilters = {};

      this.newQueryParams(this.newParams);
    },

    handleApplyFilter(value) {
      const { filter = {}, ...params } = value || {};

      this.activeFilters = {
        ...params,
        ...filter,
      };

      this.newQueryParams({ ...this.newParams, ...value });
    },

    async handleSorting(sorting) {
      this.sorting = sorting;

      this.onPageChanged(1);
    },

    async getPropertyListItems() {
      let result = {};
      try {
        result = await roomAllotmentApi.getPropertyList();
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async getRoomListItems(allParams) {
      let result = {};
      try {
        result = await roomAllotmentApi.getTableView(allParams);
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async processingPropertyListItems() {
      if (this.xSelectedPropertyIsEmpty) {
        this.propertyListSelect.length = 0;
        this.loadingAll = true;

        const response = await this.getPropertyListItems();
        const list = response.data.data;

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const item in list) {
          this.propertyListSelect.push({
            val: list[item],
            label: item,
          });
        }

        this.loadingAll = false;
      }
    },

    // Get data based on property id and filters and display it on the table body
    async processingRoomListItems(allParams) {
      this.loadingTable = true;
      this.propertyListTable.length = 0;
      this.pagination = {};

      const response = await this.getRoomListItems(allParams);
      const list = response.data;

      this.pagination = { ...list.pagination };

      this.propertyListTable = list.data.map(item => ({
        bookingCode: item.booking_code,
        tenant: item.contact_name,
        status: {
          label: item.booking_status,
          date: dateFormatterToDisplay(item.booking_status_updated_at),
        },
        number: item.room_name,
        type: item.listing_type,
        checkin: dateFormatterToDisplay(item.checkin_date),
        checkout: dateFormatterToDisplay(item.checkout_date),
        rentPrice: {
          price: item.invoice_amount,
          period: item.contract_period,
        },
        dueDate: dateFormatterToDisplay(item.scheduled_date),
        invoiceId: item.invoice_id,
        contractId: item.contract_id,
        phoneNumber: item.phone_number,
        rentDuration: item.rent_duration,
        roomStatus: item.room_status,
        showOptions: false,
      }));

      this.loadingTable = false;
    },

    newQueryParams(payload) {
      if (payload) {
        this.newParams = {
          ...payload,
          ...payload.filter,
        };

        delete this.newParams.filter;

        this.offset = 0;
      }

      const allParams = {
        property_id: this.xSelectedPropertyId,
        offset: this.offset,
        ...this.newParams,
        ...this.sorting,
      };

      Object.keys(allParams).forEach(key => {
        if (!allParams[key]) {
          delete allParams[key];
        }
      });

      this.processingRoomListItems(allParams);
    },

    onPageChanged(page) {
      this.offset = (page - 1) * this.pagination.limit;
      this.newQueryParams();
    },

    updateQueryString() {
      this.$router.push(`?property_id=${this.xSelectedPropertyId}`);
    },

    redirectToCalendarView() {
      this.$router.push({
        path: `/room-allotment/calendar-view?property_id=${this.xSelectedPropertyId}`,
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumbs = [
      {
        name: 'Daftar Properti',
        url: '/',
      },
      {
        name: '...',
      },
      {
        name: 'Ketersediaan Kamar',
      },
    ];
    next();
  },
};
</script>

<style lang="scss" src="./RoomAllotmentTableView.scss" scoped></style>
